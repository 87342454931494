import { useMemo } from "react"
import { Icon } from "./Icons"

export function Eyebrow({
  children,
  size,
  style,
  filled,
  icon,
}: {
  children: string | JSX.Element | JSX.Element[]
  size: "m" | "l"
  style: "primary" | "secondary" | "inverted-primary" | "inverted-secondary" | "primary-clear"
  filled?: boolean
  icon?: string
}) {
  const classes = useMemo(() => {
    const commonClasses = `flex w-auto float-left gap-3 items-center`

    let sizeClasses = ``
    switch (size) {
      case "m":
        sizeClasses = `type-label-m`
        break
      case "l":
        sizeClasses = `type-label-l`
        break
    }

    let styleClasses = ``
    switch (style) {
      case "primary":
        styleClasses = `text-text bg-bg`
        break
      case "primary-clear":
        styleClasses = `text-text`
        break
      case "secondary":
        styleClasses = `text-textAlt`
        break
      case "inverted-primary":
        styleClasses = `text-invert`
        break
      case "inverted-secondary":
        styleClasses = `text-invertAlt`
        break
    }

    let filledClasses = ``
    switch (filled) {
      case true:
        filledClasses = `px-4 py-3 md:py-2 rounded-sm border ${style === "inverted-primary" ? `border-invert` : style === "inverted-secondary" ? `border-invertAll` : `border-primary`}`
        break
      default:
        filledClasses = `py-3`
        break
    }

    return `${commonClasses} ${sizeClasses} ${styleClasses} ${filledClasses}`
  }, [])

  const iconClasses = useMemo(() => {
    let styleClasses = ``
    switch (style) {
      case "primary":
        styleClasses = `text-accent`
        break
      case "secondary":
        styleClasses = `text-accent`
        break
      case "inverted-primary":
        styleClasses = `text-invertAlt`
        break
      case "inverted-secondary":
        styleClasses = `text-accent`
        break
    }

    return `${styleClasses}`
  }, [])

  return (
    <div className={classes}>
      {icon && <Icon name={icon} className={`w-5 h-5 ${iconClasses}`} />}
      {children}
    </div>
  )
}
